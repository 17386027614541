import {Routes} from '@angular/router';
import { loggedOut } from '@features/auth/framework/guards/session-guards';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.routes'),
  },
  {
    path: 'guest',
    loadChildren: () => import('./modules/guest/guest.routes'),
  },
  {
    path: 'host',
    loadChildren: () => import('./modules/host/host.routes'),
  },
  {
    path: 'register',
    canMatch: [loggedOut],
    loadComponent: () => import('./shared/infrastructure/pages/register/register.component').then(c => c.RegisterPageComponent)
  },
  {
    path: 'payment-success',
    loadComponent: () => import('./shared/infrastructure/pages/payment-success/payment-success-page.component').then(c => c.PaymentSuccessPageComponent),
  },
  {
    path: 'payment-cancel',
    loadComponent: () => import('./shared/infrastructure/pages/payment-failed/payment-failed-page.component').then(c => c.PaymentFailedPageComponent),
  },
  {
    path: 'auth/reset-password/:restoreCode',
    loadComponent: () => import('./shared/infrastructure/pages/reset-password/reset-password.component').then(c => c.ResetPasswordPageComponent),
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "guest",
  }
];
